<template>
  <!-- 真人選單 type2 -->
  <div
    id="menu-live-type2"
    class="menu mb-3"
  >
    <!-- header -->
    <div class="h-14 menu__header d-flex align-center px-4">
      <div class="menu__header--title font-weight-bold icon--text">
        {{ data.data.navbar[0].game_type }}
      </div>
    </div>

    <!-- items -->
    <div>
      <swiper
        ref="gameItem"
        :options="swiperOption"
        class="mySwiper px-4 py-0"
      >
        <swiper-slide
          v-for="game in gameItems"
          :key="game._id"
          class="game__item"
        >
          <div
            class="game__item--card cursor-pointer"
            @click="lauchGame(game.game_code)"
          >
            <div
              class="game-card rounded"
              :style="gradientBg"
            >
              <v-img
                :src="baseImgUrl + game.image_h5"
                class="rounded"
                height="152"
              />
              <div class="w-100 game-name d-flex align-end subtitle-2 card1--text rounded-b">
                <span class="ml-4 mb-2">
                  {{ game.title }}
                </span>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import hexToRgba from 'hex-to-rgba'
import { resStatus } from '../../../../utils/resUtils'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    swiperOption: {
      slidesPerView: 2.1,
      spaceBetween: 8,
      // centeredSlides: true,
      centeredSlidesBounds: true,
    },
  }),

  computed: {
    ...mapGetters(['baseImgUrl', 'theme', 'gradientBg']),

    ...mapState({
      isLogin: state => state.user.isLogin,
    }),

    gameItems() {
      return this.data.data.navbar[0].items
    },

    primaryColor() {
      return this.$vuetify.theme.themes[this.theme]?.primary || ''
    },

    vendorBg() {
      return `background:linear-gradient(${hexToRgba(this.primaryColor)}, ${hexToRgba(this.primaryColor, '.3')});`
    },
  },

  methods: {
    ...mapActions([
      'get_game_url',
      'show_alert',
      'set_progress_status',
      'get_game_url_by_device',
    ]),

    /**
     * 啟動遊戲
     * @date 2021-07-02
     * @param {string} gameCode 遊戲代碼
     */
    lauchGame(gameCode) {
      // 如果尚未登入
      if (!this.isLogin) {
        return this.show_alert({
          icon: 'fail',
          text: this.$t('flashMessage.loginFirst'),
        })
      }

      this.goBlankPage(gameCode)
    },

    /**
     * 另開遊戲視窗 (live)
     * @date 2021-08-04
     * @param {string} gameCode 遊戲代碼
     */
    async goBlankPage(gameCode) {
      this.set_progress_status(true)
      this.getGameUrl(gameCode)
    },

    /**
     * 取得遊戲 url (成功)
     * @date 2021-07-02
     * @param {string} gameUrl 遊戲連結 url
     */
    getGameUrlSuccess(gameUrl) {
      this.show_alert({ icon: 'done' })
      this.gameUrl = gameUrl
      this.set_progress_status(false)
      window.open(gameUrl, '_blank')
    },

    /**
     * 取得遊戲 url (失敗)
     * @date 2021-07-02
     */
    getGameUrlFail() {
      this.set_progress_status(false)
      this.show_alert({ icon: 'fail' })
      this.$router.replace({ path: '/' })
    },

    /**
     * 取得遊戲 url
     * @date 2021-07-02
     * @param {object} gameCode 遊戲代碼
     */
    async getGameUrl(gameCode) {
      const res = await this.get_game_url_by_device({ gamecode: gameCode })
      resStatus(res, this.getGameUrlSuccess, this.getGameUrlFail)
    },
  },
}
</script>

<style lang="scss" scoped>
.h-14 {
	height: 48px;
}

.h-172px {
	height: 172px;
}

.menu__header--title {
	font-size: 18px;
}

.game-card {
	position: relative;

	.game-name {
		position: absolute;
		bottom: 0;
		left: 0;
		height: 50%;
		background-image: linear-gradient(to top, #10202c, rgba(16, 32, 44, 0));
	}
}
</style>
